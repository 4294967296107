import VideoComponent from './Video';
import Play from './Play';
import Views from './Views';
import Likes from './Likes';
import { useRef, useState } from 'react';

const getThumbnailStyle = (thumbnailSize, postStyle, layout) => ({
    position: 'relative',
    width: thumbnailSize === 'large' ? '385px' : '100%',
    height: ['large', 'portrait'].includes(thumbnailSize) || layout === 'masonry' ? '100%' : '269px',
    overflow: 'hidden',
    borderRadius: postStyle === "regular" ? '8px' : '0px',
    background: 'black',
    margin: 'auto',
});

const PostImage = ({
    isPro,
    post,
    feedSettings,
    activeIndex,
    setActiveIndex,
    postIndex,
    isPlaying,
    setIsPlaying,
    layout,
    handlePostClick,
    isFeedEditor
}) => {
    const thumbnailSize = layout === 'list' ? 'large' : (feedSettings?.feedTemplate === 'widget' ? 'portrait' : 'medium');
    const postStyle = feedSettings?.postStyle || 'regular';
    const playerExperience = isPro && feedSettings?.videoPlayer || 'lightbox';
    const noMetaClass = isPro && (feedSettings?.postElements?.includes('views') || feedSettings?.postElements?.includes('likes')) ? '' : 'sb-no-meta';

    const [dynamicHeight, setDynamicHeight] = useState('inherit');
    const imageWrapRef = useRef(null);

    const handleImageClick = () => {
        if (!isFeedEditor) {
            if (playerExperience === 'lightbox' || layout === 'gallery') {
                handlePostClick(postIndex);
            } else {
                setIsPlaying(true);
                setActiveIndex(postIndex);
            }
            if (imageWrapRef.current) {
                const height = imageWrapRef.current.clientHeight;
                setDynamicHeight(`${height}px`);
            }
        }
    };

    return (
        <div
            ref={imageWrapRef}
            className='sb-post-item-image-wrap'
            style={getThumbnailStyle(thumbnailSize, postStyle, layout)}
        >

            {isPlaying && isPro && post?.video_url && activeIndex === postIndex && layout !== 'gallery' ? (
                <VideoComponent
                    post={post}
                    playerExperience={playerExperience}
                    dynamicHeight={dynamicHeight} // Pass dynamicHeight to VideoComponent
                />
            ) : (
                <img
                    className='sb-post-item-image'
                    src={post?.local_cover_image_url || post?.cover_image_url}
                    onClick={handleImageClick}
                />
            )}

            {(layout === 'gallery' || (!isPlaying || activeIndex !== postIndex) && layout !== 'list') && (
                <>
                    {feedSettings?.postElements?.includes('playIcon') &&
                        <Play className={noMetaClass} />
                    }

                    <div className='sb-post-item-meta'>
                        {isPro && feedSettings?.postElements?.includes('views') &&
                            <Views post={post} />
                        }

                        {isPro && feedSettings?.postElements?.includes('likes') &&
                            <Likes post={post} />
                        }
                    </div>
                </>
            )}
        </div>
    );
}

export default PostImage;