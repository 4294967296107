import React, { useEffect, useState } from "react";

const VideoComponent = ({ post, playerExperience, dynamicHeight }) => {
  const [currentPost, setCurrentPost] = useState(post);

  useEffect(() => {
    setCurrentPost(post);
  }, [post]);

  // Determine video styles based on playerExperience and dynamicHeight
  const videoStyles = () => {
    let height = dynamicHeight || 'inherit';

    switch (playerExperience) {
      case "inline":
        return {
          height: height, // Set the height based on dynamicHeight
          width: "100%", // Inherit the parent's width
          backgroundColor: "black",
        };
      case "lightbox":
      default:
        return {
          height: "80vh",
          width: "100%",
        };
    }
  };

  // Determine video behavior based on playerExperience
  const videoProps = () => {
    switch (playerExperience) {
      case "inline":
        return {
          autoPlay: true,
        };
      case "lightbox":
      default:
        return {
          autoPlay: true,
          loop: true,
        };
    }
  };

  if (currentPost.id !== post.id) {
    return "";
  }

  return (
    <video
      {...videoProps()}
      controls
      style={videoStyles()}
    >
      <source src={currentPost?.video_url ?? ""} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoComponent;
