import SbUtils from '../../../Utils/SbUtils';
import './SinglePost.css';
import Author from './Elements/Author';
import PostDetails from './Elements/PostDetails';
import PostImage from './Elements/PostImage';

// Helper functions
const filterHeaderData = (headerData, post) => {
    if (headerData?.length > 1) {
        headerData = headerData.filter(header => header.open_id === post.open_id);
    }
    return headerData[0] || headerData;
};

const determineLayout = (template, layout, numberOfColumns) => {
    const carouselTemplates = ['carousel', 'showcase_carousel'];
    return (carouselTemplates.includes(template) && layout === 'carousel' && numberOfColumns === 1) ? 'list' : layout;
};

const SinglePost = ({
    post,
    headerData,
    postIndex,
    feedSettings,
    isPro,
    isFeedEditor,
    handlePostClick,
    isPlaying,
    setIsPlaying,
    activeIndex,
    setActiveIndex
}) => {
    let layout = feedSettings?.layout,
        template = feedSettings?.feedTemplate,
        numberOfColumns = parseInt(feedSettings?.carouselDesktopColumns);

    headerData = filterHeaderData(headerData, post);
    layout = determineLayout(template, layout, numberOfColumns);

    const layoutClass = layout === 'list' ? 'sb-post-list-item' : 'sb-post-block-item';

    return (
        <div className='sb-post-item-wrap'  key={postIndex}>
            <div className={`sb-post-item sbtt-post-item ${layoutClass}`} data-post-id={post?.id} key={postIndex}>

                {isPro && feedSettings?.postElements?.includes('author_info') && layout !== 'list' &&
                    <Author headerData={headerData} />
                }

                {feedSettings?.postElements?.includes('thumbnail') && (
                    <PostImage
                        isPro={isPro}
                        post={post}
                        feedSettings={feedSettings}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        postIndex={postIndex}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        layout={layout}
                        handlePostClick={handlePostClick}
                        isFeedEditor={isFeedEditor}
                    />
                )}

                <PostDetails
                    isPro={isPro}
                    post={post}
                    feedSettings={feedSettings}
                    headerData={headerData}
                    postIndex={postIndex}
                    layout={layout}
                    isFeedEditor={isFeedEditor}
                />

            </div>
            {isFeedEditor && SbUtils.addHighlighter('tiktokfeed', 'Posts', postIndex)}
        </div>
    )
}

export default SinglePost;
