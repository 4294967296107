import { __ } from '@wordpress/i18n';
import SbUtils from '../../../Utils/SbUtils'
import Button from '../../Common/Button';
import './Header.css'

const Header = ({ feedSettings, headerData, isPro, isFeedEditor }) => {
    const avatarSize = feedSettings?.headerAvatar || 'small';
    headerData = headerData[0] || headerData;

    return (
        feedSettings.showHeader &&
        headerData && headerData?.display_name &&
        <section className='sb-feed-header sb-fs' data-align='left'>
            {isFeedEditor && SbUtils.addHighlighter('header', 'Header', 0, true)}

            <div className='sb-feed-header-content'>
                <div className='sb-feed-header-profile'>
                    {
                        feedSettings.headerContent.includes('avatar') &&
                        <div className='sb-relative'>
                            <img className={`sb-feed-header-logo ${avatarSize}`}
                                src={headerData?.local_avatar_url || headerData?.avatar_url_100}
                                alt={__('Header Logo', 'sb-customizer')}
                            />
                            {isFeedEditor && SbUtils.addHighlighter('header-avatar', 'Profile Picture')}
                        </div>
                    }

                    <div className='sb-feed-header-user-info'>
                        <div className='sb-feed-header-display-name'>
                            {
                                feedSettings.headerContent.includes('name') &&
                                <span className='sb-relative'>
                                    <span className='sb-feed-header-name'>{headerData?.display_name}</span>
                                    {isFeedEditor && SbUtils.addHighlighter('header-name', 'Name')}
                                </span>
                            }

                            {
                                feedSettings.headerContent.includes('username') &&
                                <span className='sb-relative'>
                                    <span className='sb-feed-header-username'>@{headerData?.username}</span>
                                    {isFeedEditor && SbUtils.addHighlighter('header-username', 'Username')}
                                </span>
                            }
                        </div>

                        {
                            isPro && feedSettings.headerContent.includes('description') && headerData?.bio_description &&
                            <div className='sb-feed-header-description sb-relative'>
                                <span>{headerData?.bio_description}</span>
                                {isFeedEditor && SbUtils.addHighlighter('header-description', 'Description')}
                            </div>
                        }

                        {
                            isPro && feedSettings.headerContent.includes('stats') &&
                            <div className='sb-feed-header-stats-info sb-relative'>
                                <span>
                                    <span className='sb-feed-header-stats'>{headerData?.following_count}{" "}</span>
                                    <span className='sb-feed-header-stats-description'>{__('Following', 'sb-customizer')}</span>
                                </span>

                                <span>
                                    <span className='sb-feed-header-stats'>{headerData?.follower_count}{" "}</span>
                                    <span className='sb-feed-header-stats-description'>{__('Followers', 'sb-customizer')}</span>
                                </span>

                                <span>
                                    <span className='sb-feed-header-stats'>{headerData?.likes_count}{" "}</span>
                                    <span className='sb-feed-header-stats-description'>{__('Likes', 'sb-customizer')}</span>
                                </span>
                                {isFeedEditor && SbUtils.addHighlighter('header-stats', 'Stats')}
                            </div>
                        }
                    </div>
                </div>

                {
                    feedSettings.headerContent.includes('button') &&
                    <div className='sb-feed-header-btn-ctn sb-relative'>
                        <Button
                            customClass='sb-feed-header-btn sbtt-feed-header-btn'
                            text={feedSettings.headerButtonContent}
                            link={headerData?.profile_deep_link}
                            target='_blank'
                        />
                        {isFeedEditor && SbUtils.addHighlighter('header-button', 'Button')}
                    </div>
                }
            </div>
        </section>
    )
}

export default Header;